import { toast } from 'react-toastify';

export const pathServer = {
  PATH_ICONS: `${process.env.PUBLIC_URL}/images/icons/`,
  PATH_IMG: `${process.env.PUBLIC_URL}/images/img/`,
};
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss a';
export const typeResquest = {
  NEW: 'new',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const ACCEPT_REQUEST = 'approved';
export const DENIED_REQUEST = 'denied';
export const PENDING_REQUEST = 'pending';

export const STORAGE_TOKEN_COGNITO = 'idTokenCognito';
export const FIELD_NEXTTIME_LOGIN = 'nextTime';

export const REASSON_1 = 'El contratante no figura en el sistema como cliente.';
export const REASSON_2 =
  'El representante de la empresa contratante no reconoce al solicitante como personal de la empresa.';
export const REASSON_3 = 'El documento adjunto no corresponde a la Ficha RUC de la empresa.';

export const showSuccessToast = (message) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const showErrorToast = (message) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const DOMAIN_READ_ONLY = 'protectasecuritygroup.com';

export const services = {
  20000001: {
    icon: 'prdRentasVitalicias',
    name: 'Rentas Vitalicias',
  },

  20000002: {
    icon: 'prdDesgravamen',
    name: 'Desgravamen',
  },
  20000010: {
    icon: 'prdRentaTotal',
    name: 'Vida Individual de Corto Plazo',
  },
  20000004: {
    icon: 'prdVidaLey',
    name: 'Vida Ley',
  },

  20000005: {
    icon: 'prdSCTR',
    name: 'SCTR',
  },

  20000006: {
    icon: 'prdAccidentesP',
    name: 'Accidentes Personales',
  },
  20000007: { icon: 'prdSoat', name: 'SOAT' },

  20000008: {
    icon: 'prdRentaTotal',
    name: 'Renta Total Protecta',
  },

  20000003: {
    icon: 'prdAhorroTotal',
    name: 'Ahorro Total Protecta',
  },

  20000011: {
    icon: 'prdRentaTotal',
    name: 'Vida Devolución Protecta',
  },
  20000012: {
    icon: 'prdRentaTotal', // update
    name: 'Sepelio',
  },
  20000013: {
    icon: 'prdVidaCreditoFacil',
    name: 'Vida Crédito Fácil',
  },
};

export const iconsProducts = {
  20000001: {
    icon: 'iconRentasVitalicias',
    name: 'Rentas Vitalicias',
  },
  20000002: {
    icon: 'iconDesgravamen',
    name: 'Desgravamen',
  },
  20000010: {
    icon: 'iconRentasVitalicias',
    name: 'Vida Individual de Corto Plazo',
  },
  20000004: {
    icon: 'iconRentasVitalicias',
    name: 'Vida Ley',
  },
  20000005: {
    icon: 'iconRentasVitalicias',
    name: 'SCTR',
  },
  20000006: {
    icon: 'iconAccidentesP',
    name: 'Accidentes Personales',
  },
  20000007: { icon: 'iconSoat', name: 'SOAT' },
  20000008: {
    icon: 'iconRentaTotal',
    name: 'Renta Total Protecta',
  },
  20000003: {
    icon: 'iconAhorroTotal',
    name: 'Ahorro Total Protecta',
  },
  20000011: {
    icon: 'iconVDP',
    name: 'Vida Devolución Protecta',
  },
  20000012: {
    icon: 'iconVDP', // update
    name: 'Sepelio',
  },
};

export const productsCode = {
  RENTA_VITALICIA: 'RRVV',
  RENTA_TOTAL: 'Rt',
  AHORRO_TOTAL: 'At',
  SOAT: 'SOAT',
};

export const menuCode = {
  RENTA_VITALICIA: '20000001',
  RENTA_TOTAL: '20000008',
  AHORRO_TOTAL: '20000003',
  SOAT: '20000007',
  VIDA_LEY: '20000004',
  VIDA_DEVOLUCION: '20000011',
  VIDA_CREDITO_FACIL: '20000013',
  VIDA_INDIVIDUAL: '20000010',
  ACCIDENTES_PERSONALES: '20000006',
  DESGRAVAMEN: '20000002',
};

export const LINE_BREAK_CODE = '\n';

export const productsTitle = {
  [menuCode.RENTA_VITALICIA]: 'Boletas',
  [menuCode.RENTA_TOTAL]: 'Liquidación de pago',
  [menuCode.SOAT]: 'Certificado Digital',
};

export const NAME_FILE = 'listadoUsuariosProtecta.csv';
export const HEADERS_FIELDS_CSV = [
  { label: 'Tipo documento', key: 'tipoDoc' },
  { label: 'Numero documento', key: 'nroDoc' },
  { label: 'Nombres', key: 'name' },
  { label: 'Correo electronico', key: 'mail' },
  { label: 'Estado', key: 'status' },
  { label: 'Bloqueado', key: 'enabledText' },
  { label: 'Celular', key: 'phone' },
  { label: 'Promociones', key: 'pdp' },
  { label: 'Origen', key: 'origin' },
  { label: 'Fecha de registro', key: 'createDate' },
  { label: 'Fecha de actualización', key: 'updateDate' },
];

export const HEADERS_FIELDS_CSV_SURVEYS = [
  { label: 'Tipo documento', key: 'typeDocument' },
  { label: 'Numero documento', key: 'nroDocument' },
  { label: 'Nombres', key: 'name' },
  { label: 'Fecha', key: 'date' },
  { label: 'Mes', key: 'month' },
  { label: 'Puntos', key: 'points' },
  { label: 'Canal', key: 'channelSV' },
];

export const NAME_FILE_COMPANY = 'listadoUsuariosEmpresaProtecta.csv';
export const NAME_FILE_SURVEY = 'listadoUsuariosEncuestaProtecta.csv';

export const HEADERS_FIELDS_COMPANY_CSV = [
  { label: 'RUC', key: 'ruc' },
  { label: 'Nombres', key: 'name' },
  { label: 'Correo electronico', key: 'mail' },
  { label: 'Estado', key: 'status' },
  { label: 'Bloqueado', key: 'enabledText' },
  { label: 'Promociones', key: 'pdp' },
  { label: 'Fecha de registro', key: 'createDate' },
  { label: 'Fecha de actualización', key: 'updateDate' },
];

export const STORAGE_TOKEN_APP = 'idTokenApp';

export const Timer = {
  waitingApi: 2000,
  waitingApiLogin: 5000,
};
export const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;

export const PARAMS_PAGINATE = {
  previousLabel: '<',
  nextLabel: '>',
  breakLabel: '...',
  breakClassName: 'break-me',
  containerClassName: 'pagination',
  activeClassName: 'active',
  pageRangeDisplayed: 6,
  marginPagesDisplayed: 2,
};

export const STATUS_REQUEST = {
  PENDING: 'Pendiente',
  APPROVED: 'Aceptada',
  REJECTED: 'Rechazada',
};
