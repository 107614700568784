import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

import './index.scss';

const LoadingContainer = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const Loading = React.forwardRef(({ height, width, className }, ref) => {
  return (
    <LoadingContainer ref={ref} height={height} width={width} className={`container-loading ${className}`}>
      <div />
      <div />
      <div />
      <div />
    </LoadingContainer>
  );
});


Loading.displayName = 'Loading';

Loading.defaultProps = {
  className: '',
  height: 80,
  width: 80,
};

Loading.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Loading;