import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PSLoading from './PSLoading';
import { menuCode } from '../shared/constants/Constants';
import { Paginator } from './molecules';

const ID_SEGMENT_RRVV = menuCode.RENTA_VITALICIA;
const StyledPSDynamicBasicTable = styled.div`
  background: #fff;
  width: 100%;
  .tr-disabled {
    background: #80808012;
    .table-item {
      cursor: not-allowed;
      color: #7a7a7a !important;
      pointer-events: none;
    }
  }
  .container-table {
    border-spacing: 0px 10px;
    width: 100%;
    text-align: left;
    .table-header {
      color: #002655;
      font-size: 12px;
      padding-left: 10px;
      .table-header-field {
        padding: 0.5rem 10px;
      }
      .header-info-basic {
        padding-left: 10px;
      }
      .th-check {
        width: 15px;
      }
    }
    .table-item {
      font-family: 'Gotham-Book', sans-serif;
      color: #002655;
      padding: 0.7rem 10px;
      .item-actions {
        display: flex;
        justify-content: space-around;
      }
      .item-info-basic {
        font-family: 'Gotham-Medium', sans-serif;
        display: flex;
        .info-photo {
          width: 32px;
          height: 32px;
          border-radius: 50px;
          background: red;
          margin-right: 10px;
          svg {
            border-radius: 15px;
          }
        }
        .info-name {
          font-size: 12px;
          text-transform: capitalize;
        }
        .info-mail {
          font-size: 10px;
          opacity: 0.5;
        }
      }
    }
  }
  .pagination {
    li {
      padding: 12px 0 !important;
      border: none;
      &:hover {
        cursor: pointer;
      }
      a {
        color: #002655 !important;
      }
    }
    .active {
      padding: 5px 10px;
      border-radius: 50%;
      background: #de6d19 !important;
      a {
        color: white !important;
      }
    }
  }
`;

const MAX_PER_PAGE = 6;

export const PSDynamicBasicTable = React.forwardRef(
  ({ headers, items, onSelect, loading, productSelected, statusFilter }, ref) => {
    const [actualPage, setActualPage] = useState(1);
    const listPaginate = items?.slice((actualPage - 1) * MAX_PER_PAGE, actualPage * MAX_PER_PAGE);
    const itemsIterable = Math.ceil(items.length / MAX_PER_PAGE);

    useEffect(() => {
      setActualPage(1);
    }, [items]);

    return (
      <StyledPSDynamicBasicTable ref={ref}>
        {!loading ? (
          <>
            <table className="table-product-details">
              {items.length > 0 && (
                <thead>
                  <tr>
                    {headers.map((header) => (
                      <th key={header.elementProp} className="table-header-field color-orange">
                        {header.headerColumn}
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {listPaginate?.map((item, index) => {
                  /*  TODO: remove disabled validation in policy table
                const isDevolutionLife = productSelected.menuId === menuCode.VIDA_DEVOLUCION;
                const isDisabled = isDevolutionLife
                ? item.status !== 'Vigente' && item.status !== 'Suspendida'
                 : item.status !== 'Vigente';
                */
                  if (productSelected.menuId === ID_SEGMENT_RRVV) {
                    item.dateVigencia = `Desde ${item.fechaInicioVigencia}`;
                  }

                  const isDisabled = false;
                  return (
                    <tr
                      className={isDisabled ? 'tr-disabled ' : 'tr-enabled'}
                      key={`${item.id}-${index}`}
                      onClick={(evt) => onSelect(item, evt)}
                    >
                      {headers.map((header) => (
                        <td className="table-item" key={item.id + header.elementProp}>
                          {header.type === 'HTML' ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item[header.elementProp],
                              }}
                            />
                          ) : (
                            item[header.elementProp]
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {items.length > MAX_PER_PAGE && (
              <div className="mt-2">
                <Paginator
                  pageCount={itemsIterable}
                  actualPage={actualPage - 1}
                  onPageChange={(page) => setActualPage(page.selected + 1)}
                  forcePage
                />
              </div>
            )}
            {items.length === 0 && !loading && (
              <p>No hemos encontrado pólizas {statusFilter ? `de estado ${statusFilter}` : ''}</p>
            )}
          </>
        ) : (
          <div className="loading-container">
            <PSLoading color="orange" />
          </div>
        )}
      </StyledPSDynamicBasicTable>
    );
  }
);

PSDynamicBasicTable.displayName = 'PSDynamicBasicTable';

PSDynamicBasicTable.defaultProps = {
  headers: [],
  items: [],
  onSelect: () => {},
  loading: false,
};

PSDynamicBasicTable.propTypes = {
  headers: PropTypes.array,
  items: PropTypes.array,
  onSelect: PropTypes.func,
  loading: PropTypes.bool,
};

export default PSDynamicBasicTable;
