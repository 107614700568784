/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

import {
  ACCEPT_REQUEST,
  DENIED_REQUEST,
  PENDING_REQUEST,
  showErrorToast,
  showSuccessToast,
  typeResquest,
} from '../../../shared/constants/Constants';
import { ItemRequest } from '../../../shared/model/ItemRequest';
import RequestCompany from '../../../shared/services/RequestCompany';

import PSTabs from '../../../components/PSTabs';
import PSCard from '../../../components/PSCard';
import PSCardDetail from '../../../components/PSCardDetail';
import PSModal from '../../../components/PSModal';
import PSSolicitud from '../../../components/PSSolicitud';
import PSLoading from '../../../components/PSLoading';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Paginator } from '../../../components/molecules';
import { getPaginatedList, sortAction, sortList, sortByAttribute } from '../../../../scripts/Util';

const MAX_PER_PAGE = 10;
const tabsSolicitudes = [
  { id: 0, name: 'Solicitudes nuevas', className: '', selected: true },
  { id: 1, name: 'Solicitudes aceptadas', className: '', selected: false },
  { id: 2, name: 'Solicitudes rechazadas', className: '', selected: false },
];

export default function CompanyRequest() {
  const [showDetail, setShowDetail] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [requests, setRequestType] = useState(tabsSolicitudes);
  const [requestAproveeds, setRequestAproveeds] = useState([]);
  const [requestAproveedsSearch, setRequestAproveedsSearch] = useState([]);
  const [onSearchRuc, setOnSearchRuc] = useState(false);
  const [requestSelected, setRequestSelected] = useState(null);
  const [showModalAccept, setShowModalAccept] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);
  const [reassonReject, setReassonReject] = useState(null);
  const [observations, setObservations] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [pageSelected, setPageSelected] = useState(1);

  const ORDERING_FUNCTIONS = [
    {
      condition: onSearchRuc,
      action: () => getPaginatedList(requestAproveedsSearch, pageSelected, MAX_PER_PAGE),
    },
  ];

  const listPaginate = getPaginatedList(requestAproveedsSearch, pageSelected, MAX_PER_PAGE);

  const itemsIterable = Math.ceil(requestAproveedsSearch.length / MAX_PER_PAGE);
  const user = useSelector((state) => state.user);
  const { isReadOnly } = user;
  const typeRequest = requests[0].selected
    ? typeResquest.NEW
    : requests[1].selected
      ? typeResquest.ACCEPTED
      : typeResquest.REJECTED;

  const onSelTab = (index) => {
    setCurrentIndex(index);
    const requestsNew = tabsSolicitudes.map((t) => {
      // eslint-disable-next-line no-param-reassign
      if (t.id === index) t.selected = true;
      // eslint-disable-next-line no-param-reassign
      else t.selected = false;
      return t;
    });
    setRequestType(requestsNew);
    getRequest();
  };

  const getRequest = async () => {
    setShowLoading(true);
    setPageSelected(1);
    try {
      let response;
      const type = requests[0].selected
        ? typeResquest.NEW
        : requests[1].selected
          ? typeResquest.ACCEPTED
          : typeResquest.REJECTED;
      switch (type) {
        case typeResquest.NEW:
          response = await RequestCompany.responseGetRequestAprovee(PENDING_REQUEST);
          break;
        case typeResquest.ACCEPTED:
          response = await RequestCompany.responseGetRequestAprovee(ACCEPT_REQUEST);
          break;
        case typeResquest.REJECTED:
          response = [];
          response = await RequestCompany.responseGetRequestAprovee(DENIED_REQUEST);
          break;
        default:
          response = await RequestCompany.responseGetRequestAprovee(PENDING_REQUEST);
          break;
      }
      const arrRequest = response.map((r) => new ItemRequest(r));
      const sortResponse = sortList(arrRequest);
      setRequestAproveeds(sortResponse);
      setRequestAproveedsSearch(sortResponse);
      setShowLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const onDownloadFile = async (request) => {
    try {
      const data = await RequestCompany.getFileRUC(request.rucFile);
      const link = document.createElement('a');
      link.href = data;
      link.download = 'rucFile.pdf';
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      showSuccessToast('Archivo descargado');
    } catch (error) {
      console.log('error', error);
    }
  };

  const onAcceptRequest = async (status) => {
    const request = requestSelected;
    if ((observations && status === DENIED_REQUEST) || status === ACCEPT_REQUEST) {
      try {
        const body = {
          id: `R${request.rucNumber}`,
          email: request.contactEmail || '',
          updateBy: `${user.given_name} ${user.middle_name}`,
          nombre: `${request.contactName} ${request.contactLastName}`,
          comment: reassonReject || '-',
          processStatus: status,
          legalName: request.legalName || '-',
          observations: observations || '-',
        };
        await RequestCompany.sendRequestToAprovee(body, status);
        showSuccessToast(
          status === ACCEPT_REQUEST ? 'Aceptando solicitud ...' : 'Rechazando solicitud .. '
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        // eslint-disable-next-line no-unused-expressions
        status === ACCEPT_REQUEST ? setShowModalAccept(false) : setShowModalReject(false);
      } catch (e) {
        showErrorToast('Error');
      }
    } else {
      showErrorToast('Es necesario que seleccione un motivo.');
    }
  };

  const onSearch = (value) => {
    if (value) {
      setOnSearchRuc(true);
      const filter = sortByAttribute(requestAproveeds, value, 'rucNumber');
      setRequestAproveedsSearch(filter);
    } else {
      setOnSearchRuc(false);
      setRequestAproveedsSearch(requestAproveeds);
    }

    setPageSelected(1);
  };

  const sortedList = sortAction(ORDERING_FUNCTIONS, 'condition', listPaginate);

  useEffect(() => {
    getRequest();
  }, []);

  return !showDetail ? (
    <div className="company-request">
      <div className="company-request__header">
        <h2 className="color-blue">Solicitudes</h2>
        <PSTabs
          options={requests}
          onSelecTab={(index) => onSelTab(index)}
          onSearch={(value) => {
            onSearch(value);
          }}
          onClear={currentIndex}
        />
      </div>
      {showLoading ? (
        <div className="loading-container">
          <PSLoading color="orange" width={200} height={200} />
        </div>
      ) : (
        <>
          <div className="company-request__body">
            {sortedList.map((request, index) => (
              <PSCard
                request={request}
                type={typeRequest}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                isReadOnly={isReadOnly}
                showDetail={(req) => {
                  setRequestSelected(req);
                  setShowDetail(true);
                }}
                acceptRequest={() => {
                  setRequestSelected(request);
                  setShowModalAccept(true);
                }}
                rejectRequest={() => {
                  setRequestSelected(request);
                  setShowModalReject(true);
                }}
                downloadFile={(requestTmp) => onDownloadFile(requestTmp)}
              />
            ))}
          </div>
          {listPaginate.length > 0 && (
            <div className="company-request__pagination">
              <Paginator
                pageCount={itemsIterable}
                actualPage={pageSelected - 1}
                onPageChange={(pageSel) => setPageSelected(pageSel.selected + 1)}
              />
            </div>
          )}
        </>
      )}

      {showModalAccept && (
        <PSModal open onClose={() => setShowModalAccept(false)}>
          {' '}
          <PSSolicitud onSubmit={() => onAcceptRequest(ACCEPT_REQUEST)} isAccept />
        </PSModal>
      )}
      {showModalReject && (
        <PSModal
          open
          onClose={() => {
            setShowModalReject(false);
            setObservations(null);
          }}
        >
          {' '}
          <PSSolicitud
            onChangeAttribute={(e, type) => {
              if (type === 'area') {
                setReassonReject(e.target.value);
              } else {
                setObservations(e.target.value);
              }
            }}
            onSubmit={() => onAcceptRequest(DENIED_REQUEST)}
            isAccept={false}
          />
        </PSModal>
      )}
    </div>
  ) : requests[0].selected ? (
    <PSCardDetail
      downloadFile={(requestTmp) => onDownloadFile(requestTmp)}
      onCloseDetail={() => setShowDetail(false)}
      request={requestSelected}
      typeRequest={typeResquest.NEW}
    />
  ) : requests[1].selected ? (
    <PSCardDetail
      downloadFile={(requestTmp) => onDownloadFile(requestTmp)}
      onCloseDetail={() => setShowDetail(false)}
      request={requestSelected}
      typeRequest={typeResquest.ACCEPTED}
    />
  ) : (
    <PSCardDetail
      downloadFile={(requestTmp) => onDownloadFile(requestTmp)}
      onCloseDetail={() => setShowDetail(false)}
      request={requestSelected}
      typeRequest={typeResquest.REJECTED}
    />
  );
}
