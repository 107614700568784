import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Routes } from '../routes';

import TestPage from './pages/Test';
import LoginPage from './pages/Login';

import ForgotPassword from './pages/RecoverPassword/SignUpPassword';
import ForgotPasswordValidation from './pages/RecoverPassword/SignUpValidation';
import ForgotCreatePassword from './pages/RecoverPassword/CreatePassword';

import { withHome } from './components/PSHome';
import CompanyUser from './pages/NetPrivada/CompanyUser';
import CompanyRequest from './pages/NetPrivada/CompanyRequest';
import NaturalUser from './pages/NetPrivada/NaturalUser';
import HomePage from './pages/Home';
import NewSletter from './pages/NewSletter';
import Boletin from './pages/Boletin';
import Boletines from './pages/Boletines';
import Products from './pages/Products';
import FrequentQuestion from './pages/FrequentQuestion';
import ProductsByUser from './pages/ProductsByUser';
import RolesPage from './pages/Setting/Roles';
import SurveySatisfaction from './pages/SurveySatisfaction';
import SurveySatisfactionDashboardPage from './pages/SurveySatisfaction/Dashboard';
import ManagementPromotions from './pages/ManagementPromotions';
import { PERMISSION_LIST } from '../scripts/Constants';
import { includesArray } from '../scripts/Util';
import { withContract } from './hocs/PSManagerContractor';
import { ContractorHome } from './components/templates';
import CollaboratorPageContractor from './pages/Contract/Collaborator';
import CommunityPageContractor from './pages/Contract/Community';
import ContractsPageContractor from './pages/Contract/Contracts';
import RequestPageContractor from './pages/Contract/Request';
import TemplatePageContractor from './pages/Contract/Templates';
import { withLogin } from './hocs/PSHomeLogin';
import ManagementMenu from './pages/ManagementMenu';

const RoutesPage = () => {
  const { errorLogin, administrator } = useSelector((state) => state.user);
  let permissionAdministrator = administrator
    ? administrator.roles.filter((r) => administrator.info.roles.includes(r.sort))
    : null;
  if (permissionAdministrator) {
    permissionAdministrator = permissionAdministrator.map((p) => p.menuOptions)[0];
  }
  // permissionAdministrator = PERMISSION_DEFAULT.map((p) => p.submenu).flat();
  const PrivateRoute = ({ component, path, hasAccess = true, ...props }) => {
    if (errorLogin === null && hasAccess) {
      return <Route component={component} path={path} {...props} />;
    }
    return <Redirect to={Routes.LOGIN} {...props} />;
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={Routes.LOGIN} />} />
        <Route Route path={Routes.LOGIN} exact component={LoginPage} />
        <Route Route path={Routes.FORGOT_PASSWORD} exact component={withLogin(ForgotPassword)} />
        <Route
          Route
          path={Routes.FORGOT_PASSWORD_VALIDATION}
          exact
          component={withLogin(ForgotPasswordValidation)}
        />
        <Route
          Route
          path={Routes.FORGOT_PASSWORD_CREATE_PASSWORD}
          exact
          component={withLogin(ForgotCreatePassword)}
        />

        <PrivateRoute path={Routes.TEST} exact component={withHome(TestPage)} />
        <PrivateRoute path={Routes.HOME} exact component={withHome(HomePage)} />
        <PrivateRoute
          path={Routes.NET_COMPANY_REQUEST}
          exact
          component={withHome(CompanyRequest)}
        />

        <PrivateRoute
          path={Routes.NET_COMPANY_USER}
          exact
          component={withHome(CompanyUser)}
          hasAccess={
            permissionAdministrator &&
            includesArray(permissionAdministrator, PERMISSION_LIST.Net_Empresas)
          }
        />
        <PrivateRoute
          path={Routes.NET_NATURAL_USER}
          exact
          component={withHome(NaturalUser)}
          hasAccess={
            permissionAdministrator &&
            includesArray(permissionAdministrator, PERMISSION_LIST.Net_Naturales)
          }
        />
        <PrivateRoute
          path={Routes.NEWSLETTER_GENERAL}
          exact
          component={withHome(NewSletter)}
          hasAccess={
            permissionAdministrator &&
            includesArray(permissionAdministrator, PERMISSION_LIST.Newsletter_General)
          }
        />

        <PrivateRoute path={Routes.NEWSLETTER_CATEGORY} exact component={withHome(NewSletter)} />
        <PrivateRoute
          path={`${Routes.NEWSLETTER_CATEGORY}/:id`}
          exact
          component={withHome(NewSletter)}
        />

        <PrivateRoute
          path={`${Routes.NET_NATURAL_USER_PRODUCTS}/:id`}
          exact
          component={withHome(ProductsByUser)}
        />
        <PrivateRoute
          path={`${Routes.NEWSLETTER_BOLETIN_CREATE}/:id`}
          exact
          component={withHome(Boletin)}
        />
        <PrivateRoute
          path={`${Routes.NEWSLETTER_BOLETIN_ALL}/:id`}
          exact
          component={withHome(Boletines)}
        />
        <PrivateRoute path={Routes.PRODUCTS} exact component={withHome(Products)} />
        <PrivateRoute path={Routes.FAQ} exact component={withHome(FrequentQuestion)} />

        <PrivateRoute
          path={Routes.GENERAL_ROLES}
          exact
          component={withHome(RolesPage)}
          hasAccess={
            permissionAdministrator &&
            includesArray(permissionAdministrator, PERMISSION_LIST.Roles_Permisos)
          }
        />
        <PrivateRoute
          path={Routes.SURVEY_SATISFACTION}
          exact
          component={withHome(SurveySatisfaction)}
          hasAccess={
            permissionAdministrator &&
            includesArray(permissionAdministrator, PERMISSION_LIST.Encuesta_Satisfaccion)
          }
        />
        <PrivateRoute
          path={Routes.SURVEY_SATISFACTION_DASHBOARD}
          exact
          component={withHome(SurveySatisfactionDashboardPage)}
          hasAccess={
            permissionAdministrator &&
            includesArray(permissionAdministrator, PERMISSION_LIST.Encuesta_Satisfaccion)
          }
        />

        <PrivateRoute
          path={Routes.MANAGEMENT_PROMOTIONS}
          exact
          component={withHome(ManagementPromotions)}
        />
        <PrivateRoute path={Routes.MANAGEMENT_MENU} exact component={withHome(ManagementMenu)} />
        <PrivateRoute path={Routes.CONTRACT.HOME} exact component={withContract(ContractorHome)} />

        <PrivateRoute
          path={Routes.CONTRACT.COLLABORATORS}
          exact
          component={withContract(CollaboratorPageContractor)}
        />

        <PrivateRoute
          path={Routes.CONTRACT.COMMUNITY}
          exact
          component={withContract(CommunityPageContractor)}
        />

        <PrivateRoute
          path={Routes.CONTRACT.CONTRACTS}
          exact
          component={withContract(ContractsPageContractor)}
        />

        <PrivateRoute
          path={Routes.CONTRACT.REQUEST}
          exact
          component={withContract(RequestPageContractor)}
        />
        <PrivateRoute
          path={Routes.CONTRACT.TEMPLATES}
          exact
          component={withContract(TemplatePageContractor)}
        />
      </Switch>
    </Router>
  );
};

export default RoutesPage;
