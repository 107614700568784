import { isEmptyArray } from '../../../scripts/Util';

export class ProductPolicies {
  constructor(props) {
    this.menuId = props.product;
    this.menuDescription = props.productDescription;
    this.policies = props.policies;
    this.deprecatedPolicies = props.deprecatedPolicies;
    this.policiesHistoric = [...props.policies, ...props.deprecatedPolicies];
    this.productVigency = !isEmptyArray(props.policies);
  }
}
