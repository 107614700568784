import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HeaderSection } from '../../molecules';
import { Button, SelectCustom } from '../../atoms';
import { ButtonColor } from '../../atoms/button/Button';
import PSModal from '../../PSModal';
import Accordion from '../../organisms/accordion';
import { showErrorToast, showSuccessToast } from '../../../shared/constants/Constants';

import './index.scss';

const ConfigurationMenu = React.forwardRef(
  ({ className, accordionData, setUpdatedProductsList, listOptions }, ref) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAddOptionModal, setShowAddOptionModal] = useState(false);
    const [productSelected, setProductSelected] = useState(null);
    const [optionSelected, setOptionSelected] = useState(null);
    const [filterOptionsList, setFilterOptionsList] = useState(listOptions);

    const openModalOption = (segment) => {
      setOptionSelected(null);
      setProductSelected(segment);

      const currentOptions = accordionData
        .find((p) => p.segment === segment)
        ?.options?.map((e) => e.id);

      // filter obtions by product, not repeat
      const filterOptions = listOptions?.filter((o) => !currentOptions.includes(o.value));
      setFilterOptionsList(filterOptions);

      setShowAddOptionModal(true);
    };
    const addOption = () => {
      if (optionSelected) {
        const { value, label } = optionSelected;
        const currentOption = { id: value, value: label, available: true };
        accordionData.find((p) => p.segment === productSelected).options.push(currentOption);
        setUpdatedProductsList(accordionData);
        setShowAddOptionModal(false);
        showSuccessToast(`Se agregó una nueva opción con éxito`);
      } else {
        showErrorToast('Debe seleccionar la opción a agregar');
      }
    };

    const saveChanges = () => {
      setShowConfirmModal(false);
      showSuccessToast(`Los cambios se guardaron con éxito`);
    };

    const onChangeElement = (type, checked, segment, id) => {
      const currentData = [...accordionData];
      const indexProduct = currentData.findIndex((p) => p.segment === segment);

      if (type === 'product') {
        currentData[indexProduct].available = checked;
        setUpdatedProductsList(currentData);
      } else {
        const listOptionsByProd = currentData[indexProduct].options;
        const indexOption = listOptionsByProd.findIndex((op) => op.id === id);
        currentData[indexProduct].options[indexOption].available = checked;
        setUpdatedProductsList(currentData);
      }
    };

    return (
      <div className={`${className} configuration-role `} ref={ref}>
        <div className="container-role">
          <HeaderSection
            title="Gestión de Productos"
            buttonHeader={
              <Button type={ButtonColor.blue} onClick={() => setShowConfirmModal(true)}>
                Guardar
              </Button>
            }
          />
          <div className="configuration-body">
            <div className="accordion">
              {accordionData.map(({ ...props }) => (
                <Accordion
                  key={props.segment}
                  addOption={openModalOption}
                  onChangeElement={onChangeElement}
                  {...props}
                />
              ))}
            </div>
          </div>
        </div>

        {showConfirmModal && (
          <PSModal
            onClose={() => {
              setShowConfirmModal(false);
            }}
            open={showConfirmModal}
            center
            classNames={{
              overlay: 'customOverlay',
              modal: 'saveChangesModal',
            }}
          >
            <div className="option_container">
              <div className="option_subtitle">
                <h4>Confirmar cambios</h4>
              </div>
              <p>¿Está seguro de realizar el cambio en todos los elementos seleccionados?</p>
              <div className="option_buttons">
                <Button
                  type={ButtonColor.white}
                  className="button_white"
                  onClick={() => {
                    setShowConfirmModal(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button className="button_blue" onClick={saveChanges}>
                  Confirmar
                </Button>
              </div>
            </div>
          </PSModal>
        )}
        {showAddOptionModal && (
          <PSModal
            onClose={() => {
              setShowAddOptionModal(false);
            }}
            open={showAddOptionModal}
            center
            classNames={{
              overlay: 'customOverlay',
              modal: 'optionModal',
            }}
          >
            <div className="option_container">
              <div className="option_subtitle">
                <h4>Agregar opción</h4>
              </div>
              <div className="option_select">
                <SelectCustom
                  className="select-option"
                  placeholder="Seleccione opción"
                  options={filterOptionsList}
                  onChange={(e) => setOptionSelected(e)}
                />
              </div>
              <div className="option_buttons">
                <Button
                  type={ButtonColor.white}
                  className="button_white"
                  onClick={() => {
                    setShowAddOptionModal(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button className="button_blue" onClick={addOption}>
                  Agregar
                </Button>
              </div>
            </div>
          </PSModal>
        )}
      </div>
    );
  }
);

ConfigurationMenu.displayName = 'PSConfigurationMenu';

ConfigurationMenu.defaultProps = {
  className: '',
};

ConfigurationMenu.propTypes = {
  className: PropTypes.string,
};

export default ConfigurationMenu;
