export const accordionData = [
  {
    available: true,
    segment: '20000001',
    title: 'Rentas Vitalicias',
    options: [
      { id: 'DCP', value: 'Descarga de Comprobante de Pago', available: false },
      { id: 'CDA', value: 'Constancia de Asegurado', available: true },
    ],
  },
  {
    available: true,
    segment: '20000002',
    title: 'Desgravamen',
    options: [
      { id: 'DCP', value: 'Descarga de Comprobante de Pago', available: false },
      { id: 'CDA', value: 'Constancia de Asegurado', available: true },
    ],
  },
  {
    available: true,
    segment: '20000003',
    title: 'Ahorro Total Protecta',
    options: [{ id: 'DCP', value: 'Descarga de Comprobante de Pago', available: true }],
  },
  {
    available: false,
    segment: '20000004',
    title: 'Vida Ley',
    options: [{ id: 'DCP', value: 'Descarga de Comprobante de Pago', available: true }],
  },
  {
    available: false,
    segment: '20000005',
    title: 'SCTR',
    options: [{ id: 'DCP', value: 'Descarga de Comprobante de Pago', available: true }],
  },
  {
    available: false,
    segment: '20000006',
    title: 'Accidentes Personales',
    options: [{ id: 'DCP', value: 'Descarga de Comprobante de Pago', available: true }],
  },
  {
    available: true,
    segment: '20000007',
    title: 'SOAT',
    options: [
      { id: 'DCP', value: 'Descarga de Comprobante de Pago', available: false },
      { id: 'CDA', value: 'Constancia de Asegurado', available: true },
    ],
  },
  {
    available: true,
    segment: '20000008',
    title: 'Renta Total Protecta',
    options: [
      { id: 'DCP', value: 'Descarga de Comprobante de Pago', available: false },
      { id: 'CDA', value: 'Constancia de Asegurado', available: true },
    ],
  },
  {
    available: true,
    segment: '20000010',
    title: 'Vida Individual de Corto Plazo',
    options: [
      { id: 'DCP', value: 'Descarga de Comprobante de Pago', available: false },
      { id: 'CDA', value: 'Constancia de Asegurado', available: true },
    ],
  },
  {
    available: false,
    segment: '20000011',
    title: 'Vida Devolución Protecta',
    options: [
      { id: 'DCP', value: 'Descarga de Comprobante de Pago', available: true },
      { id: 'CP', value: 'Constancia de Pensionista', available: false },
    ],
  },
  {
    available: false,
    segment: '20000012',
    title: 'Sepelio',
    options: [
      { id: 'DCP', value: 'Descarga de Comprobante de Pago', available: true },
      { id: 'CP', value: 'Constancia de Pensionista', available: false },
    ],
  },
  {
    available: false,
    segment: '20000013',
    title: 'Vida Crédito Fácil',
    options: [
      { id: 'DCP', value: 'Descarga de Comprobante de Pago', available: true },
      { id: 'CP', value: 'Constancia de Pensionista', available: false },
    ],
  },
];
export const listOptions = [
  { value: 'CP', label: 'Cronograma de pago' },
  { value: 'CPS', label: 'Constancia de Pensionista' },
  { value: 'BLT', label: 'Mis Boletas' },
  { value: 'CDA', label: 'Constancia de asegurado' },
  { value: 'CDLA', label: 'Constancia de Listado de Asegurados' },
  { value: 'DCP', label: 'Descarga de comprobante de pago' },
];
