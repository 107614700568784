import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PSIcon from './PSIcon';
import { typeResquest, STATUS_REQUEST } from '../shared/constants/Constants';
import { formatDateTime, getFirstLetter } from '../../scripts/Util';

const StyledPSCard = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  grid-gap: 17px;
  color: #002655;
  .section-request {
    border-right: 1px solid #dadada;
    .first-row {
      display: flex;
      justify-content: space-between;
      margin: 2rem 0;
      .request-info {
        display: flex;
        .icon-pdf {
          margin: 0 2rem 0rem 1rem;
          svg {
            width: 48px !important;
            height: 49px !important;
          }
        }
      }
      .icon-download {
        margin: 0 48px;
        cursor: pointer;
        &: {
          cursor: pointer;
        }
      }
      .detail-size-file {
        color: #b9c1c7;
        font-family: 'Gotham-book', sans-serif;
        font-size: 17px;
      }
    }
    .second-row {
      margin: 1rem 2rem 1rem 1rem;
      .card {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 3px 3px 10px #004eaf12;
        border-radius: 10px;
        margin: 2rem 0;
      }
      .card-summary {
        display: grid;
        grid-template-columns: 0.33fr 0.67fr;
        grid-gap: 17px;
        height: 125px;
        .circle {
          margin: auto;
          width: 83px;
          height: 83px;
          background: #555454 0% 0% no-repeat padding-box;
          opacity: 1;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          .icon {
            padding: 0.4rem;
          }
        }

        .card-summary-name {
        }
        .card-summary-ruc {
          color: #002655;
          opacity: 0.5;
          margin-top: 1rem;
        }
      }
      .card-approved {
        display: flex;
        height: 100px;
        .approved-title {
          font-size: 12px;
          color: #de6d19;
        }
        .approved-name {
          font-family: 'Gotham-Book', sans-serif;
          font-size: 16px;
          margin: 1rem 0;
        }
        .calendar {
          display: flex;
          font-family: 'Gotham-Book', sans-serif;
          font-size: 14px;
          & div:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
      .attend-request {
        display: flex;
        justify-content: center;
        .margin-15 {
          margin-right: 15%;
        }
      }
    }
  }
  .section-information {
    margin: 0 2rem;
    .box-information {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 3px 3px 10px #004eaf12;
      border-radius: 10px;
      height: 58px;
      margin: 1rem 0;
      display: flex;
      .information-field {
        color: #de6d19;
        font-size: 12px;
        letter-spacing: 0px;
      }
      .information-value {
        font-family: 'Gotham-Book', sans-serif;
        color: #002655;
        font-size: 16px;
        margin: 0.5rem 0;
      }
    }

    .box-comments {
      background: #f7f8f9 0% 0% no-repeat padding-box;
      border-radius: 10px;
      min-height: 137px;
      .comments-title {
        color: #d12228;
        font-size: 15px;
        font-family: 'Gotham-Medium', sans-serif;
      }

      .comments-content {
        font-family: 'Gotham-Book', sans-serif;
        font-size: 15px;
        margin: 1rem 0;
      }
    }

    .p-1 {
      padding: 1rem;
    }
  }
`;

const PSCardDetail = React.forwardRef(
  ({ className, downloadFile, onClick, onCloseDetail, typeRequest, request, ...props }, ref) => {
    const dateString = formatDateTime(request.date);
    const updateDateString = formatDateTime(request.lastUpdate);
    const messageType =
      // eslint-disable-next-line no-nested-ternary
      typeRequest === typeResquest.NEW
        ? STATUS_REQUEST.PENDING
        : typeRequest === typeResquest.REJECTED
          ? STATUS_REQUEST.REJECTED
          : STATUS_REQUEST.APPROVED;
    return (
      <div className="company-request__detail">
        <div className="company-request__detail-back">
          <PSIcon
            type="arrowBack"
            className="company-request__detail-back-icon"
            onClick={() => onCloseDetail()}
          />
          <div className="company-request__detail-back-title">
            <div className="title">
              <label>Solicitud {messageType}</label>
            </div>
            <div className="calendar">
              <PSIcon type="calendarOrange" />
              <div>
                <label>{dateString}</label>
              </div>
            </div>
          </div>
        </div>
        <StyledPSCard {...props} ref={ref} className={className}>
          <div className="section-request">
            <div className="first-row">
              <div className="request-info">
                <PSIcon type="pdf" className="icon-pdf" />
                <div>
                  <div>ArchivoRUC</div>
                  <div className="detail-size-file">143 KB</div>
                </div>
              </div>
              <PSIcon
                type="downloadGreen"
                className="icon-download"
                onClick={() => downloadFile(request)}
              />
            </div>
            <div className="second-row">
              <div>
                <label>Empresa</label>
              </div>
              <div className="card card-summary">
                <div className="circle">
                  <PSIcon type="companyWhite" className="icon" />
                </div>
                <div className="ma-0">
                  <div className="card-summary-name">
                    <label>{request.legalName}</label>
                  </div>
                  <div className="card-summary-ruc">
                    <label>RUC {request.rucNumber} </label>
                  </div>
                  <div className="card-summary-ruc">
                    <label>{request.businessEmail} </label>
                  </div>
                </div>
              </div>

              {(typeRequest === typeResquest.ACCEPTED || typeRequest === typeResquest.REJECTED) && (
                <div className="card card-approved">
                  <div className="ma-0">
                    <div className="approved-title">
                      {typeRequest === typeResquest.ACCEPTED && <label>Aceptado por</label>}
                      {typeRequest === typeResquest.REJECTED && <label>Rechazado por</label>}
                    </div>
                    <div className="approved-name">
                      <label>{request.aproveeOwner}</label>
                    </div>
                    <div className="calendar">
                      <PSIcon type="calendarOrange" />
                      <div>
                        <label>{updateDateString}</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="section-information">
            <div>
              <label>Contacto</label>
            </div>
            <div>
              <div className="box-information">
                <div className="ma-1">
                  <div className="information-field">DNI/Carnet de Extranjería</div>
                  <div className="information-value">{getFirstLetter(request.contactDocument)}</div>
                </div>
              </div>
              <div className="box-information">
                <div className="ma-1">
                  <div className="information-field">Nombre Completo</div>
                  <div className="information-value">{`${request.contactName} ${request.contactLastName} ${request.contactLastName2}`}</div>
                </div>
              </div>
              <div className="box-information">
                <div className="ma-1">
                  <div className="information-field">Correo</div>
                  <div className="information-value">{request.contactEmail}</div>
                </div>
              </div>
              <div className="box-information">
                <div className="ma-1">
                  <div className="information-field">Teléfono</div>
                  <div className="information-value">{request.contactPhone}</div>
                </div>
              </div>
              <div className="box-information">
                <div className="ma-1">
                  <div className="information-field">Anexo</div>
                  <div className="information-value">{request.contactAnexo}</div>
                </div>
              </div>

              {typeRequest === typeResquest.REJECTED && (
                <div className="box-comments">
                  <div className="p-1">
                    <label className="comments-title">Motivo de rechazo</label>
                    <div className="comments-content">{request.mainReasson}</div>
                    <div className="comments-content">{request.secondaryReasson}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </StyledPSCard>
      </div>
    );
  }
);

PSCardDetail.displayName = 'PSCardDetail';

PSCardDetail.defaultProps = {
  onClick: () => { },
  className: null,
  request: {},
  typeRequest: typeResquest.ACCEPTED,
  onCloseDetail: () => { },
  downloadFile: () => { },
};

PSCardDetail.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  request: PropTypes.object,
  typeRequest: PropTypes.string,
  onCloseDetail: PropTypes.func,
  downloadFile: PropTypes.func,
};

export default PSCardDetail;
