import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { menuCode } from '../../../shared/constants/Constants';
import { Routes } from '../../../../routes';
import { POLICY_STATUS } from '../../../../scripts/Constants';

import { ListProducts } from '../../organisms';
import PSDynamicBasicTable from '../../PSDynamicBasicTable';
import PSLoading from '../../PSLoading';
import PSTabs from '../../PSTabs';
import PSIcon from '../../PSIcon';
import { SelectCustom } from '../../atoms';

import './index.scss';

const headerTable = [
  { elementProp: 'producto', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
  { elementProp: 'statusHTML', headerColumn: 'Estado', type: 'HTML' },
];

const headerSOATTable = [
  { elementProp: 'placa', headerColumn: 'Placa' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
  { elementProp: 'marca', headerColumn: 'Marca' },
  { elementProp: 'modelo', headerColumn: 'Modelo' },
  { elementProp: 'statusHTML', headerColumn: 'Estado', type: 'HTML' },
];
const headerVITable = [
  { elementProp: 'producto', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'nroCertificate', headerColumn: 'Número de certificado' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
  { elementProp: 'statusHTML', headerColumn: 'Estado', type: 'HTML' },
];

export default function ProductsByUser({
  listProducts,
  selectUserProduct,
  selectDetail,
  listPoliceByProducts,
  loading,
  loadingDetails,
  productSelected,
  beneficary,
  policiesTypeFilter,
  setPoliciesTypeFilter,
  productTypeFilter,
  setProductTypeFilter,
  tabsPolicyViewer,
  statusFilter,
  setStatusFilter,
}) {
  const [listProductsByFilter, setListProductsByFilter] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setListProductsByFilter([...listProducts.filter((p) => p.productVigency)]);
  }, [listProducts]);

  useEffect(() => {
    if (productTypeFilter === 'valid') {
      const productsFilter = listProducts.filter((p) => p.productVigency);
      setListProductsByFilter(productsFilter);
    } else {
      setListProductsByFilter([...listProducts]);
    }
  }, [productTypeFilter]);

  const customHeaderTable = () => {
    if (productSelected.branchId === 66) return headerSOATTable;
    const productWithCertificate = [
      menuCode.VIDA_INDIVIDUAL,
      menuCode.DESGRAVAMEN,
      menuCode.ACCIDENTES_PERSONALES,
    ];
    if (productWithCertificate.includes(productSelected.menuId)) return headerVITable;
    return headerTable;
  };

  const onSelectTab = (indexChange) => {
    setPoliciesTypeFilter(indexChange);
  };

  return !loading ? (
    <div className="container-products-user">
      <div className="general-title">
        <PSIcon
          type="arrowBack"
          className="icon-arrow-back"
          onClick={() => {
            history.push(`${Routes.NET_NATURAL_USER}`);
          }}
        />
        <div className="item-info-basic">
          {/* <div className="info-photo">
                    <PSIcon type="profile"/>
                  </div>   */}
          <div>
            <h2 className="info-name">{beneficary.name}</h2>
            <div className="info-mail">
              {beneficary.tipoDoc} {beneficary.nroDoc}
            </div>
          </div>
        </div>
      </div>

      <div id="containerProductDetails" className="container-product-details container-between">
        <div className="table-title">Productos Contratados</div>

        <div className="options-detail">
          <div className="card-option">
            <input
              type="radio"
              id="valid"
              name="filter"
              value="false"
              checked={productTypeFilter === 'valid'}
              onChange={() => setProductTypeFilter('valid')}
            />
            <label htmlFor="valid">Vigente</label>
          </div>
          <div className="card-option">
            <input
              type="radio"
              id="all"
              name="filter"
              value="true"
              checked={productTypeFilter === 'all'}
              onChange={() => setProductTypeFilter('all')}
            />
            <label htmlFor="all">Todos</label>
          </div>
        </div>
      </div>

      <div className="container-user-products">
        {listProductsByFilter.length > 0 ? (
          <ListProducts
            listProducts={listProductsByFilter}
            selectProduct={selectUserProduct}
            productSelected={productSelected}
            className="products-by-user"
          />
        ) : (
          <p>El cliente no cuenta con productos vigentes</p>
        )}
      </div>
      {listProducts.length > 0 && productSelected && (
        <div id="containerPolicyDetails" className="container-product-details">
          <div className="table-title">Detalle del seguro</div>
          <div className="container-between">
            <PSTabs
              options={tabsPolicyViewer}
              onSelecTab={(id) => onSelectTab(id)}
              hasFilters={false}
              hasButtonSubmit={false}
            />
            {policiesTypeFilter === 2 && (
              <div className="container-state">
                <span>Estado</span>
                <SelectCustom
                  className="select-status"
                  placeholder="Todos"
                  options={POLICY_STATUS}
                  onChange={({ value, optional }) => setStatusFilter({ value, optional })}
                />
              </div>
            )}
          </div>

          <PSDynamicBasicTable
            headers={customHeaderTable()}
            items={listPoliceByProducts}
            onSelect={selectDetail}
            loading={loadingDetails}
            productSelected={productSelected}
            statusFilter={statusFilter?.value}
          />
        </div>
      )}
    </div>
  ) : (
    <div className="align-all-center">
      <PSLoading color="default" />
    </div>
  );
}
