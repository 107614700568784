import { statusHTML } from '../../../scripts/Util';

export class Policy {
  constructor(props) {
    this.id = props.id;
    this.idPolicy = props.idPolicy;
    this.producto = props.shortDescription;
    this.nroPolicy = props.policy;
    this.nroCertificate = props.certificateId;
    this.rol = props.rol;
    this.fechaInicioVigencia = props.startPolicyVigency;
    this.fechaFinVigencia = props.endPolicyVigency;
    this.endoso = props.endoso;
    this.nbranch = props.branchId.toString();
    this.idProduct = props.productId.toString();
    this.dateVigencia = `${props.startPolicyVigency} ${props.endPolicyVigency && 'al'} ${
      props.endPolicyVigency
    }`;
    this.status = props.status;
    this.statusHTML = statusHTML(props.status);
    this.placa = props?.vehicle?.license;
    this.marca = props?.vehicle?.brand;
    this.modelo = props?.vehicle?.model;
    this.prima = `${props?.premium?.currencyShortDescription} ${props?.premium?.amount}`;
  }
}
