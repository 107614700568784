import React, { useState } from 'react';
import { sortArrayByField } from '../../../../scripts/Util';
import { Switch } from '../../atoms';
import PSIcon from '../../PSIcon';

import './index.scss';

const Accordion = ({ addOption, onChangeElement, ...props }) => {
  const { title, options = [], segment, available = true, icon } = props;
  const [isActive, setIsActive] = useState(false);
  const optionsList = sortArrayByField(options, 'value');

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={() => setIsActive(!isActive)}>
        <div className="accordion-title">
          <Switch
            isChecked={available}
            onHandle={(checked) => onChangeElement('product', checked, segment)}
            className="switch"
            id={`${segment}`}
            colorSelected="#002655"
          />
          <PSIcon type={icon} className="icon-arrow" />
          <span>{title}</span>
        </div>
        <PSIcon type={isActive ? 'chevronDown' : 'chevronUp'} className="icon-arrow" />
      </div>
      {isActive && (
        <div className="accordion-content">
          {optionsList.map(({ id, value, available: availableOpt }, index) => (
            <div className="accordion-option" key={id}>
              <Switch
                isChecked={availableOpt}
                onHandle={(checked) => onChangeElement('option', checked, segment, id)}
                className="switch"
                id={`${index}-${segment}`}
                colorSelected="#002655"
              />
              <span>{value}</span>
            </div>
          ))}
          <div className="accordion-add" onClick={() => addOption(segment)}>
            <div> +</div>
            <span> Agregar opción </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default Accordion;
