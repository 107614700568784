export const Routes = {
  BASE: '/',
  HOME: '/home',
  TEST: '/test',
  LOGIN: '/auth',
  FORGOT_PASSWORD: '/auth/forgot-password',
  FORGOT_PASSWORD_VALIDATION: '/auth/forgot-password/validation',
  FORGOT_PASSWORD_CREATE_PASSWORD: '/auth/forgot-password/create-password',
  NET_COMPANY_REQUEST: '/net/company/request',
  NET_COMPANY_USER: '/net/company/user',
  NET_NATURAL_USER: '/net/natural/user',
  NET_NATURAL_USER_PRODUCTS: '/net/natural/user/products',
  NEWSLETTER_GENERAL: '/newsletter/general',
  NEWSLETTER_BOLETIN_ALL: '/newsletter/boletin/all',
  NEWSLETTER_CATEGORY: '/newsletter/category',
  NEWSLETTER_BOLETIN_CREATE: '/newsletter/boletin/create',
  PRODUCTS: '/products',
  FAQ: '/frequent-question',
  GENERAL_ROLES: '/general/roles',
  MANAGEMENT_MENU: '/management/menu',
  MANAGEMENT_PROMOTIONS: '/management/promotions',
  SURVEY_SATISFACTION: '/survey',
  SURVEY_SATISFACTION_DASHBOARD: '/survey/dashboard',
  CONTRACT: {
    HOME: '/contract/home',
    UPDATES: '/contract/updates',
    COMMUNITY: '/contract/community',
    COLLABORATORS: '/contract/collaborators',
    CONTRACTS: '/contract/contracts',
    REQUEST: '/contract/request',
    SURVEY: '/contract/survey',
    VALIDATIONS: '/contract/validations',
    TEMPLATES: '/contract/templates',
  },
};
