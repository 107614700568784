import React, { useState } from 'react';

import { ConfigurationMenu } from '../../components/templates';
import { accordionData, listOptions } from './constant';
import { iconProducts, sortArrayByField } from '../../../scripts/Util';

export default function ManagementMenu() {
  const dataOrigin = sortArrayByField(iconProducts(accordionData), 'title');
  const [updatedProductsList, setUpdatedProductsList] = useState([...dataOrigin]);

  return (
    <ConfigurationMenu
      listOptions={listOptions}
      accordionData={updatedProductsList}
      setUpdatedProductsList={(value) => setUpdatedProductsList(value)}
    />
  );
}
