import moment from 'moment';
import { pathServer } from '../app/shared/constants/Constants';
// ICONS
export const Icons = {
  face: {
    url: `${pathServer.PATH_ICONS}carita_1.svg`,
  },
  successFace: {
    url: `${pathServer.PATH_ICONS}carita_2.svg`,
  },
  dashboard: {
    url: `${pathServer.PATH_ICONS}icon_dashboard.svg`,
  },
  chat: {
    url: `${pathServer.PATH_ICONS}Chat.svg`,
  },
  notification: {
    url: `${pathServer.PATH_ICONS}icon_camp_notificacion.svg`,
  },
  poligono: {
    url: `${pathServer.PATH_ICONS}poligono.svg`,
  },
  construct: {
    url: `${pathServer.PATH_ICONS}icon_construct.svg`,
  },
  arrowLeft: {
    url: `${pathServer.PATH_ICONS}arrow_left.svg`,
  },
  people: {
    url: `${pathServer.PATH_ICONS}people.svg`,
  },
  company: {
    url: `${pathServer.PATH_ICONS}company.svg`,
  },
  companyWhite: {
    url: `${pathServer.PATH_ICONS}company-white.svg`,
  },
  calendar: {
    url: `${pathServer.PATH_ICONS}ic_calendar.svg`,
  },
  detail: {
    url: `${pathServer.PATH_ICONS}detail.svg`,
  },
  search: {
    url: `${pathServer.PATH_ICONS}ic_search_blue.svg`,
  },
  searchOrange: {
    url: `${pathServer.PATH_ICONS}ic_search.svg`,
  },
  searchBlue: {
    url: `${pathServer.PATH_ICONS}icon_search.svg`,
  },
  time: {
    url: `${pathServer.PATH_ICONS}icon_time.svg`,
  },
  pdf: {
    url: `${pathServer.PATH_ICONS}icon_pdf.svg`,
  },
  download: {
    url: `${pathServer.PATH_ICONS}icon_download.svg`,
  },
  arrowBack: {
    url: `${pathServer.PATH_ICONS}icon_flecha_back.svg`,
  },
  arrowDown: {
    url: `${pathServer.PATH_ICONS}icon_fecha-down.svg`,
  },
  save: {
    url: `${pathServer.PATH_ICONS}icon_save.svg`,
  },
  add: {
    url: `${pathServer.PATH_ICONS}icon_add.svg`,
  },
  upload2: {
    url: `${pathServer.PATH_ICONS}icon_upload2.svg`,
  },
  laptopActivated: {
    url: `${pathServer.PATH_ICONS}icon_laptop_a.svg`,
  },
  laptopDisabled: {
    url: `${pathServer.PATH_ICONS}icon_laptop_d.svg`,
  },
  mobileActivated: {
    url: `${pathServer.PATH_ICONS}icon_mobile_a.svg`,
  },
  mobileDisabled: {
    url: `${pathServer.PATH_ICONS}icon_mobile_d.svg`,
  },
  calendarOrange: {
    url: `${pathServer.PATH_ICONS}calendar_orange.svg`,
  },
  calendarDay: {
    url: `${pathServer.PATH_ICONS}calendar_day.svg`,
  },
  smallCalendar: {
    url: `${pathServer.PATH_ICONS}small_calendar.svg`,
  },
  contractCalendar: {
    url: `${pathServer.PATH_ICONS}calendario-icon.svg`,
  },
  downloadGreen: {
    url: `${pathServer.PATH_ICONS}download_green.svg`,
  },
  unlock: {
    url: `${pathServer.PATH_ICONS}unlock.svg`,
  },
  block: {
    url: `${pathServer.PATH_ICONS}block.svg`,
  },
  delete: {
    url: `${pathServer.PATH_ICONS}delete.svg`,
  },
  accept: {
    url: `${pathServer.PATH_ICONS}icon_accept.svg`,
  },
  acceptChanged: {
    url: `${pathServer.PATH_ICONS}icon_accept2.svg`,
  },
  deny: {
    url: `${pathServer.PATH_ICONS}icon_deny.svg`,
  },
  facebook: {
    url: `${pathServer.PATH_ICONS}fb.svg`,
  },
  youtube: {
    url: `${pathServer.PATH_ICONS}yt.svg`,
  },
  userInfo: {
    url: `${pathServer.PATH_ICONS}icon_user_info.svg`,
  },
  profile: {
    url: `${pathServer.PATH_ICONS}profile.svg`,
  },
  arrowLeftBig: {
    url: `${pathServer.PATH_ICONS}arrow-left-big.svg`,
  },
  arrowRightBig: {
    url: `${pathServer.PATH_ICONS}arrow-right-big.svg`,
  },
  arrowLeftMedium: {
    url: `${pathServer.PATH_ICONS}arrow-left-medium.svg`,
  },
  arrowRightMedium: {
    url: `${pathServer.PATH_ICONS}arrow-right-medium.svg`,
  },
  upload: {
    url: `${pathServer.PATH_ICONS}icon_upload.svg`,
  },
  addCircle: {
    url: `${pathServer.PATH_ICONS}icon-add.svg`,
  },
  optionCard: {
    url: `${pathServer.PATH_ICONS}options-card.svg`,
  },
  success: {
    url: `${pathServer.PATH_ICONS}icon-success.svg`,
  },
  prdAccidentesP: {
    url: `${pathServer.PATH_ICONS}accidentes_personales.svg`,
  },
  prdDesgravamen: {
    url: `${pathServer.PATH_ICONS}desgravamen.svg`,
  },
  prdAhorroTotal: {
    url: `${pathServer.PATH_ICONS}ahorro_total.svg`,
  },
  prdRentaTotal: {
    url: `${pathServer.PATH_ICONS}renta_total.svg`,
  },
  prdVidaCreditoFacil: {
    url: `${pathServer.PATH_ICONS}vida_credito.svg`,
  },
  prdRentasVitalicias: {
    url: `${pathServer.PATH_ICONS}rentas_vitalicias.svg`,
  },
  prdSCTR: {
    url: `${pathServer.PATH_ICONS}SCTR.svg`,
  },
  prdSoat: {
    url: `${pathServer.PATH_ICONS}soat.svg`,
  },
  prdVidaGrupo: {
    url: `${pathServer.PATH_ICONS}vida_grupo.svg`,
  },
  prdVidaLey: {
    url: `${pathServer.PATH_ICONS}vida_ley.svg`,
  },
  show: {
    url: `${pathServer.PATH_ICONS}show.svg`,
  },
  heartFavorite: {
    url: `${pathServer.PATH_ICONS}icon_heart_favorite.svg`,
  },
  heart: {
    url: `${pathServer.PATH_ICONS}icon_heart.svg`,
  },
  dragDrop: {
    url: `${pathServer.PATH_ICONS}move.svg`,
  },
  arrowOrange: {
    url: `${pathServer.PATH_ICONS}arrow-orange.svg`,
  },
  arrowsDown: {
    url: `${pathServer.PATH_ICONS}ic_arrows_down.svg`,
  },
  attachFile: {
    url: `${pathServer.PATH_ICONS}ic_attach_file.svg`,
  },
  comments: {
    url: `${pathServer.PATH_ICONS}ic_comments.svg`,
  },
  mail: {
    url: `${pathServer.PATH_ICONS}icon_mail.svg`,
  },
  userCreate: {
    url: `${pathServer.PATH_ICONS}user_create.svg`,
  },
  userDelete: {
    url: `${pathServer.PATH_ICONS}user_delete.svg`,
  },
  default: {
    url: `${pathServer.PATH_ICONS}arrow-right-medium.svg`,
  },
  printFile: {
    url: `${pathServer.PATH_ICONS}ic-print.svg`,
  },
  downloadFile: {
    url: `${pathServer.PATH_ICONS}ic-download.svg`,
  },
  refresh: {
    url: `${pathServer.PATH_ICONS}ic_refresh.svg`,
  },
  satisfaction: {
    url: `${pathServer.PATH_ICONS}satisfaction.svg`,
  },
  dissatisfaction: {
    url: `${pathServer.PATH_ICONS}dissatisfaction.svg`,
  },
  user: {
    url: `${pathServer.PATH_ICONS}user.svg`,
  },
  users: {
    url: `${pathServer.PATH_ICONS}users.svg`,
  },
  users2: {
    url: `${pathServer.PATH_ICONS}ic_users.svg`,
  },
  calendarJun: {
    url: `${pathServer.PATH_IMG}calendar/jan.png`,
  },
  monitor: {
    url: `${pathServer.PATH_ICONS}icon_monitor.svg`,
  },
  mobile: {
    url: `${pathServer.PATH_ICONS}icon_mobile.svg`,
  },
  options: {
    url: `${pathServer.PATH_ICONS}options.svg`,
  },
  options2: {
    url: `${pathServer.PATH_ICONS}ic_options.svg`,
  },
  form: {
    url: `${pathServer.PATH_ICONS}form.svg`,
  },
  ignore: {
    url: `${pathServer.PATH_ICONS}ignore.svg`,
  },
  checkCircle: {
    url: `${pathServer.PATH_ICONS}check-circle.svg`,
  },
  timesCircle: {
    url: `${pathServer.PATH_ICONS}times-circle.svg`,
  },
  xCircle: {
    url: `${pathServer.PATH_ICONS}x-circle.svg`,
  },
  close: {
    url: `${pathServer.PATH_ICONS}icon_close.svg`,
  },
  closeHeader: {
    url: `${pathServer.PATH_ICONS}icon_closeOnbording.svg`,
  },
  attachment: {
    url: `${pathServer.PATH_ICONS}icon-attachment.svg`,
  },
  message: {
    url: `${pathServer.PATH_ICONS}icon-message.svg`,
  },
  fileAttached: {
    url: `${pathServer.PATH_ICONS}icon-files.svg`,
  },
  blueStar: {
    url: `${pathServer.PATH_ICONS}icon_blue_star.svg`,
  },
  fileUpload: {
    url: `${pathServer.PATH_ICONS}icon_file_upload.svg`,
  },
  folder: {
    url: `${pathServer.PATH_ICONS}icon_folder.svg`,
  },
  newFolder: {
    url: `${pathServer.PATH_ICONS}icon_new_folder.svg`,
  },
  emptyFolder: {
    url: `${pathServer.PATH_ICONS}icon_empty_folder.svg`,
  },
  recent: {
    url: `${pathServer.PATH_ICONS}icon_recent.svg`,
  },
  calendarContract: {
    url: `${pathServer.PATH_ICONS}icon-calendarContract.svg`,
  },
  iconBackRed: {
    url: `${pathServer.PATH_ICONS}icon-back-red.svg`,
  },
  iconDownRed: {
    url: `${pathServer.PATH_ICONS}icon-down-red.svg`,
  },
  pdfFile: {
    url: `${pathServer.PATH_ICONS}icon_pdf_file.svg`,
  },
  docFile: {
    url: `${pathServer.PATH_ICONS}icon_doc_file.svg`,
  },
  rightArrow: {
    url: `${pathServer.PATH_ICONS}icon_right_arrow.svg`,
  },
  download2: {
    url: `${pathServer.PATH_ICONS}icon_download_2.svg`,
  },
  user2: {
    url: `${pathServer.PATH_ICONS}icon_user.svg`,
  },
  whiteDownArrow: {
    url: `${pathServer.PATH_ICONS}icon_white_down_arrow.svg`,
  },
  task: {
    url: `${pathServer.PATH_ICONS}icon_task.svg`,
  },
  user3: {
    url: `${pathServer.PATH_ICONS}icon_user.svg`,
  },
  home: {
    url: `${pathServer.PATH_ICONS}icon_home.svg`,
  },
  password: {
    url: `${pathServer.PATH_ICONS}eye.svg`,
  },
  taskUser: {
    url: `${pathServer.PATH_ICONS}ic_task_user.svg`,
  },
  dashboardMenu: {
    url: `${pathServer.PATH_ICONS}ic_home.svg`,
  },
  requests: {
    url: `${pathServer.PATH_ICONS}ic_requests.svg`,
  },
  community: {
    url: `${pathServer.PATH_ICONS}ic_community.svg`,
  },
  logoProtecta: {
    url: `${pathServer.PATH_ICONS}ic_logo_protecta.svg`,
  },
  // password
  legalArea: {
    url: `${pathServer.PATH_ICONS}icon_legal_area.svg`,
  },
  collaborator: {
    url: `${pathServer.PATH_ICONS}icon_collaborator.svg`,
  },
  bookmark: {
    url: `${pathServer.PATH_ICONS}icon_bookmark.svg`,
  },
  orangeCalendar: {
    url: `${pathServer.PATH_ICONS}icon_orange_calendar.svg`,
  },
  blueTrash: {
    url: `${pathServer.PATH_ICONS}icon_blue_trash.svg`,
  },
  blueUpload: {
    url: `${pathServer.PATH_ICONS}icon_blue_upload.svg`,
  },
  blueArrowUp: {
    url: `${pathServer.PATH_ICONS}icon_blue_arrow_up.svg`,
  },
  orangeTrash: {
    url: `${pathServer.PATH_ICONS}icon_orange_trash.svg`,
  },
  check: {
    url: `${pathServer.PATH_ICONS}icon_check.svg`,
  },
  blueBookmark: {
    url: `${pathServer.PATH_ICONS}icon_blue_bookmark.svg`,
  },
  orangeAdd: {
    url: `${pathServer.PATH_ICONS}icon_orange_add.svg`,
  },
  uncheck: {
    url: `${pathServer.PATH_ICONS}icon_uncheck.svg`,
  },
  remove: {
    url: `${pathServer.PATH_ICONS}icon_remove.svg`,
  },
  orangeArrowDown: {
    url: `${pathServer.PATH_ICONS}icon_orange_arrow_down.svg`,
  },
  sentMessage: {
    url: `${pathServer.PATH_ICONS}icon_sent_message.svg`,
  },
  orangeCheck: {
    url: `${pathServer.PATH_ICONS}icon_orange_check.svg`,
  },
  whiteCircle: {
    url: `${pathServer.PATH_ICONS}icon_white_circle.svg`,
  },
  more: {
    url: `${pathServer.PATH_ICONS}icon_more.svg`,
  },
  filePDF: {
    url: `${pathServer.PATH_ICONS}filePDF.svg`,
  },
  fileXML: {
    url: `${pathServer.PATH_ICONS}fileXML.svg`,
  },
  chevronDown: {
    url: `${pathServer.PATH_ICONS}chevron_down.svg`,
  },
  chevronUp: {
    url: `${pathServer.PATH_ICONS}chevron_up.svg`,
  },
  iconAccidentesP: {
    url: `${pathServer.PATH_ICONS}/product/accidentes_personales.svg`,
  },
  iconAhorroTotal: {
    url: `${pathServer.PATH_ICONS}/product/ahorro_total.svg`,
  },
  iconDesgravamen: {
    url: `${pathServer.PATH_ICONS}/product/desgravamen.svg`,
  },
  iconVidaIndividual: {
    url: `${pathServer.PATH_ICONS}/product/vida_individual.svg`,
  },
  iconRentaTotal: {
    url: `${pathServer.PATH_ICONS}/product/renta_total.svg`,
  },
  iconRentasVitalicias: {
    url: `${pathServer.PATH_ICONS}/product/rentas_vitalicias.svg`,
  },
  iconSoat: {
    url: `${pathServer.PATH_ICONS}/product/soat.svg`,
  },
  iconVDP: {
    url: `${pathServer.PATH_ICONS}/product/vida_devolucion_protecta.svg`,
  },
};

// NEWSLETTER
const DATE_EXPIRATION_UNIX_DEFAULT = moment().add('d', 7).format('X');
const DATE_NOW_UNIX_DEFAULT = moment().format('X');

export const MAGAZINE_BOLETIN_VIVE_SALUD = '00000003';
export const MAGAZINE_BOLETIN_FINANCIERO = '00000004';
export const MAGAZINE_BOLETIN_EN_VIVO = '00000001';
export const MAGAZINE_BOLETIN_QUEDATE_CASA = '00000002';

export const BASE_URL_PATH_FILE_NEWSLETTER = '/newsletter/get/';
export const MAX_SIZE_UPLOAD_FILE = 10485760;
export const MAX_SIZE_UPLOAD_IMG = 248000;
export const STORAGE_TOKEN_COGNITO = 'idTokenCognito';

export const BOLETIN_OBJ = {
  idTipoContenido: '',
  principalTitle: '',
  shortDescription: '',
  dateRegister: DATE_NOW_UNIX_DEFAULT,
  dateExpiration: DATE_EXPIRATION_UNIX_DEFAULT,
  valueRanking: 5,
  paragraphs: [{ paragraph: '' }, { paragraph: '' }],
  newsletterKeywords: 'test',
  content: [
    {
      title: '',
      description: '',
    },
  ],
  buttons: [
    {
      title: 'Click aquí',
      link: '',
    },
  ],
  s3UrlPdf: '',
  s3UrlImage: '',
};

export const isSoat = (code) => code === '66';

export const SUCESS_CODE_API = 200;

export const TYPE_DOCUMENT_DNI = 'l';
export const TYPE_DOCUMENT_CARNE = 'e';
export const TYPE_DOCUMENT_PPT = 'g';

export const typesDocument = [
  { value: TYPE_DOCUMENT_DNI, label: 'DNI' },
  { value: TYPE_DOCUMENT_CARNE, label: 'Carnet de extranjería' },
  // { value: TYPE_DOCUMENT_PPT, label: 'Pasaporte' }, TODO: hide option
];
Object.freeze(typesDocument);

export const OPTION_ROLES = [
  { value: 'administrator', label: 'Administrador' },
  { value: 'editor', label: 'Editor' },
  { value: 'role', label: 'Gestor de Usuarios' },
];

export const REGEX_VALID_EMAIL =
  '/^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/;\n';

export const MONTH = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Setiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const PERMISSION_DEFAULT = [
  { id: 0, value: 'AAAA', description: 'Administradores', submenu: ['AAAA411'] },
  { id: 1, value: 'AAAB', description: 'Roles y Permisos', submenu: ['AAAB511'] },
  { id: 2, value: 'AAAC', description: 'Grupos', submenu: ['AAAC611'] },
  { id: 3, value: 'AAAD', description: 'Encuesta de Satisfacción', submenu: ['AAAD711'] },
  {
    id: 4,
    value: 'ABBA',
    description: 'NetPrivada | Config General',
    submenu: ['ABBA411', 'ABBB511', 'ABBC611', 'ABBD711', 'ABBE811', 'ABBF911'],
  },
  { id: 5, value: 'ABBB', description: 'NetPrivada | Naturales', submenu: ['ABBB411'] },
  { id: 6, value: 'ABBC', description: 'NetPrivada | Empresas', submenu: ['ABBC411'] },
  { id: 7, value: 'ACCA', description: 'Newsletter | Config General', submenu: ['ACCA411'] },
];

export const PERMISSION_LIST = {
  Administradores: ['AAAA411'],
  Roles_Permisos: ['AAAB511'],
  Grupos: ['AAAC611'],
  Encuesta_Satisfaccion: ['AAAD711'],
  Gestion_Menu: ['AAAE811'],
  Net_ConfigGeneral: ['ABBA411', 'ABBB511', 'ABBC611', 'ABBD711', 'ABBE811', 'ABBF911'],
  Net_Naturales: ['ABBB411'],
  Net_Empresas: ['ABBC411'],
  Newsletter_General: ['ACCA411'],
};

export const OPTION_SEGMENT = [
  { label: 'Todos los Segmentos', value: '10000000' },
  { label: 'Rentas Vitalicias', value: '20000001' },
  { label: 'Desgravamen', value: '20000002' },
  { label: 'Ahorro Total Protecta', value: '20000003' },
  { label: 'Vida Ley', value: '20000004' },
  { label: 'SCRT', value: '20000005' },
  { label: 'Accidentes Personales', value: '20000006' },
  { label: 'SOAT', value: '20000007' },
  { label: 'Renta Total Protecta', value: '20000008' },
  { label: 'Vida Individual de Corto Plazo', value: '20000010' },
];

export const OPTION_SEGMENT_BY_ID = {
  20000001: 'Rentas Vitalicias',
  20000002: 'Desgravamen',
  20000003: 'Ahorro Total Protecta',
  20000004: 'Vida Ley',
  20000005: 'SCRT',
  20000006: 'Accidentes Personales',
  20000007: 'SOAT',
  20000008: 'Renta Total Protecta',
  20000010: 'Vida Individual de Corto Plazo',
  10000000: 'Todos los Segmentos',
};

export const formatDate = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' };

export const POLICY_STATUS = [
  { id: 1, label: 'Todos', value: null },
  { id: 2, label: 'Vigente', value: 'Vigente' },
  { id: 3, label: 'No Vigente', value: 'No Vigente' },
  { id: 4, label: 'Anulado', value: 'Anulado', optional: 'Anulad' },
];
